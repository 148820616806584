<template>
  <BaseTootlipModal v-if="depositBonus" :link="readMoreUrl">
    <template #title> {{ tolltipTilte }} </template>
    <template #subtitle> {{ t('bonuses.depositBonus') }} </template>
    <template v-if="isStepBonus">
      <DepositBonusStepTooltip
        v-for="bonus in depositBonus.steps"
        :key="bonus.id"
        :bonus="bonus"
      />
    </template>
    <DepositBonusSingleTooltip v-else :bonus="depositBonus" />
  </BaseTootlipModal>
</template>

<script setup lang="ts">
import { useDepositBonusesStore } from '../../stores/useDepositBonusesStore'
import BaseTootlipModal from '../TooltipModal/BaseTootlipModal.vue'
import { useGetTitleByLevel } from '../../composables/useGetTitleByLevel'
import { useDepositBonus } from '../../composables/useDepositBonus'
import DepositBonusSingleTooltip from './parts/DepositBonusSingleTooltip.vue'
import DepositBonusStepTooltip from './parts/DepositBonusStepTooltip.vue'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileFreebetTerms')

const { allDepositPrograms } = storeToRefs(useDepositBonusesStore())
const depositBonus = computed(
  () =>
    allDepositPrograms.value.filter((item) => item.id === Number(props.id))[0],
)
const { filteredMultiBonuses, isStepBonus } = useDepositBonus(depositBonus)

const { getTitle } = useGetTitleByLevel()
const tolltipTilte = computed(() => {
  const titles = filteredMultiBonuses.value.map(
    (item) => `${item?.text ?? ''} ${item?.amountWithCurrency ?? ''}`,
  )
  const titlesCombinations = titles.join(' + ')
  const title = isStepBonus.value ? t('bonuses.bonus') : titlesCombinations

  return getTitle(title, depositBonus.value.level)
})
</script>
